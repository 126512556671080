import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PlayerList = ({ league, players, playersPerPage, admin, onAddPlayer }) => {
  if (!players) return null
  else players.sort((p1, p2) => (p1.name.toUpperCase() > p2.name.toUpperCase() ? 1 : -1))

  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState('');

  const filteredPlayers = players.filter(p => p.name.toUpperCase().includes(filter.toUpperCase()))

  const pageCount = Math.ceil(filteredPlayers.length / playersPerPage)
  let pages = []
  for (let i = 1; i <= pageCount; i++) {
    pages.push(
      <li key={i} id={i} active={i === currentPage ? 1 : 0} onClick={() => setCurrentPage(i)}>
        {i}
      </li>
    )
  }

  const start = (currentPage - 1) * playersPerPage
  const end = currentPage * playersPerPage
  let buttonProps = {}

  return (
    <React.Fragment>
      <form className="filter-player-form">
        <input
          type="text"
          placeholder='Filter...'
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value)
            setCurrentPage(1)}
          }
          autoComplete='off'
        />
        <button className="primary sm" onClick={event => {
          event.preventDefault(); 
          setFilter('')
        }}>
          Clear
        </button>
      </form>

      <table className='sm'>
        <tbody>
          {filteredPlayers ? (filteredPlayers
            .slice(start, end).map(player => (
              <tr key={player.id}>
                <td className='align-middle' width='80%'>
                  {player.name}
                </td>

                {admin && league &&
                    <td className='fit'>

                    {
                        (buttonProps['disabled'] = league.players.find(leaguePlayer => leaguePlayer.id === player.id)
                        ? true
                        : null)
                    }

                    <button
                        {...buttonProps}
                        className='primary sm'
                        type='submit'
                        id={player.id}
                        onClick={event => onAddPlayer(event)}
                    >
                      <FontAwesomeIcon icon='plus' />
                    </button>
                    </td>
                }
              </tr>
            ))
          ) : (
            <p>No players.</p>
          )}
        </tbody>
      </table>
      {pageCount > 1 ? (
          <ul className="pagination">{pages}</ul>
      ) : null}
    </React.Fragment>
  )
}

export default PlayerList
