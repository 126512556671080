import React from 'react'
import EventRound from './EventRound'

const LeagueEvent = ({ matches, event, admin, onScoreChange, onSaveMatches, onDropPlayer }) => {
  let rounds = []
  for (var i = 1; i <= event.rounds; i++) {
    const eventRoundProps = {
      matches,
      round: i,
      onScoreChange,
      onDropPlayer,
    }
    if (admin) eventRoundProps['admin'] = 'admin'
    rounds.push(<EventRound key={i} {...eventRoundProps} />)
  }

  return (
    <React.Fragment>
      {rounds}

      {admin && (
        <form>
          <button className='primary' onClick={event => onSaveMatches(event)}>
            Save
          </button>
        </form>
      )}
    </React.Fragment>
  )
}

export default LeagueEvent
