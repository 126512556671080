import React from 'react'

class Dialog extends React.Component {
  render() {
    return (
      <div className="modal">
        <div className="modal-content">
          {
            this.props.title && 
            <div className="modal-header">
              <h1>{this.props.title}</h1>
            </div>
          }
          <div className="modal-body">
            {this.props.content}
          </div>
          <div className="modal-footer">
            {this.props.footer}
          </div>
        </div>
      </div>
    )
  }
}

export default Dialog
