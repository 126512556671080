export function authHeader() {
  // return authorization header with basic auth credentials
  let authHeader = sessionStorage.getItem('authHeader')

  if (authHeader) {
    return { Authorization: authHeader }
  } else {
    return {}
  }
}
