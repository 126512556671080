export const CREATE_PLAYER = 'ADD_PLAYER'
export const SET_PLAYERS = 'SET_PLAYERS'

const addPlayer = (player, state) => {
  return { players: [player, ...state.players] }
}

const setPlayers = (players, state) => {
  return { players: [...players] }
}

export const PlayerReducer = (state, action) => {
  switch (action.type) {
    case CREATE_PLAYER:
      return addPlayer(action.player, state)
    case SET_PLAYERS:
      return setPlayers(action.players, state)

    default:
      return state
  }
}
