import React, { useState, useContext, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Context from '../Context/Context'
import Dialog from '../Components/Dialog'
import LeagueEvent from '../Components/LeagueEvent'
import Nav from '../Layouts/Nav'
import BeatLoader from 'react-spinners/BeatLoader'

const AdminEvent = props => {
  const context = useContext(Context)

  const { leagueId, eventId } = props.match.params

  const [loading, setLoading] = useState(false)
  const [checkedPlayers, setCheckedPlayers] = useState(new Set())
  const [rounds, setRounds] = useState(null)
  const [matches, setMatches] = useState(null)

  const [generatePairingsDialogShow, setGeneratePairingsDialogShow] = useState(false)

  const [confirmationDropPlayerDialogShow, setConfirmationDropPlayerDialogShow] = useState(false)
  const [confirmationDrop, setConfirmationDrop] = useState({ id: null, round: null })

  const league = context.leagues.find(l => l.id === leagueId)
  const event = league ? league.events.find(e => e.id === eventId) : null

  useEffect(() => {
    //get the league if we didn't already get it from the context
    if (!league) context.getLeague(leagueId)
  }, []) //only on mount

  if (!event) return null

  if (!matches) setMatches(event.matches)
  if (!rounds) setRounds(event.rounds)
  if (!checkedPlayers || checkedPlayers.size === 0) {
    event.players.forEach(ep => {
      checkedPlayers.add(ep.id)
    })
  }

  const handlePlayerChecked = e => {
    let playerId = e.target.id

    const checkedPlayersCopy = new Set(checkedPlayers)
    if (checkedPlayers.has(playerId)) checkedPlayersCopy.delete(playerId)
    else checkedPlayersCopy.add(playerId)

    setCheckedPlayers(checkedPlayersCopy)
  }

  const selectAll = e => {
    e.preventDefault()
    const checkedPlayersCopy = new Set()
    league.players.forEach(p => checkedPlayersCopy.add(p.id))
    setCheckedPlayers(checkedPlayersCopy)
  }

  const generatePairings = e => {
    e.preventDefault()

    setGeneratePairingsDialogShow(true)
  }

  const confirmGeneratePairings = e => {
    closeDialog()
    setLoading(true)

    context
      .generatePairings(event.id, league.id, [...checkedPlayers], rounds)
      .then(event => {
        setMatches(event.matches)
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const closeDialog = () => {
    setGeneratePairingsDialogShow(false)
    setConfirmationDropPlayerDialogShow(false)
  }

  const handleScoreChange = (e, matchId, playerId) => {
    let matchesCopy = [...matches]
    const match = matchesCopy.find(m => m.id === matchId)
    if (match.player1.id === playerId) match.player1_wins = e.target.value
    else if (match.player2.id === playerId) match.player2_wins = e.target.value
    setMatches(matchesCopy)
  }

  const handleDropPlayer = (e, id, round) => {
    e.preventDefault()

    setConfirmationDropPlayerDialogShow(true)
    setConfirmationDrop({ id, round })
  }

  const confirmDropPlayer = (playerId, round) => {
    closeDialog()
    setLoading(true)
    context
      .dropPlayer(event.id, league.id, playerId, round)
      .then(event => {
        setMatches(event.matches)
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleSaveMatches = e => {
    e.preventDefault()
    context.saveScores(league.id, event.id, matches)
  }

  const handlePurchasePack = (e, playerId) => {
    e.preventDefault()
    context.purchasePack(league.id, playerId)
  }

  const checkboxes = league.players
    .sort((p1, p2) => (p1.name.toUpperCase() > p2.name.toUpperCase() ? 1 : -1))
    .map(player => (
      <tr key={player.id}>
        <td>
          <Form.Check
            custom
            key={player.id}
            type='checkbox'
            id={player.id}
            label={player.name}
            onChange={event => handlePlayerChecked(event)}
            checked={checkedPlayers.has(player.id)}
          />
        </td>
        <td className='align-middle fit'>
          {player.eligiblePacks > 0 ? (
            <button onClick={event => handlePurchasePack(event, player.id)} className='primary sm'>
              <FontAwesomeIcon icon='dollar-sign' />
            </button>
          ) : player.nextLossEligible === true ? (
            <FontAwesomeIcon icon='asterisk' />
          ) : null}
        </td>
      </tr>
    ))

  return (
    <React.Fragment>
      <Nav isAuthenticated={props.authenticated} />
      <section className='section-admin'>
        {generatePairingsDialogShow && (
          <Dialog
            show={generatePairingsDialogShow}
            title={'Generate Pairings?'}
            content={'Are you sure you wish to generate the pairings?'}
            onHide={() => closeDialog()}
            footer={
              <React.Fragment>
                <button className='primary' onClick={() => closeDialog()}>
                  Cancel
                </button>
                <button className='danger' onClick={e => confirmGeneratePairings(e)}>
                  Generate
                </button>
              </React.Fragment>
            }
          />
        )}

        {confirmationDropPlayerDialogShow && (
          <Dialog
            show={confirmationDropPlayerDialogShow}
            title={'Drop Player?'}
            content={
              'Dropping a player will regenerate the pairings for that round forward, are you sure?'
            }
            onHide={() => closeDialog()}
            footer={
              <React.Fragment>
                <button className='primary' onClick={() => closeDialog()}>
                  Cancel
                </button>
                <button
                  className='danger'
                  onClick={() => confirmDropPlayer(confirmationDrop.id, confirmationDrop.round)}
                >
                  Yes, drop
                </button>
              </React.Fragment>
            }
          />
        )}

        <div className='row'>
          <h1>{event.name}</h1>
        </div>
        <div className='row breadcrumb'>
          <Link to='/admin'>Admin</Link>
          &nbsp;&nbsp;/&nbsp;&nbsp;
          <Link to={'/admin/league/' + league.id}>{league.name}</Link>
          &nbsp;&nbsp;/&nbsp;&nbsp;
          {event.name}
        </div>

        <div className='row'>
          <div className='col span-1-of-4'>
            <h2>Players ({checkedPlayers.size})</h2>

            <Form onSubmit={e => generatePairings(e)}>
              <Form.Group controlId='selectAllGroup'>
                <button className='primary' onClick={event => selectAll(event)}>
                  Select all
                </button>
              </Form.Group>

              <table className='sm'>
                <tbody>
                  {checkboxes}

                  <tr>
                    <td>Number of Rounds</td>
                    <td className='align-middle fit'>
                      <Form.Control
                        as='select'
                        value={rounds}
                        onChange={e => setRounds(e.target.value)}
                      >
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Form.Control>
                    </td>
                  </tr>
                </tbody>
              </table>

              <Form.Group controlId='generatePairingsGroup'>
                <button type='submit' className='primary'>
                  Generate Pairings
                </button>
              </Form.Group>
            </Form>
          </div>
          <div className='col span-3-of-4'>
            <h2>Matches</h2>
            {loading ? (
              <div className='loader'>
                <BeatLoader
                  css={''}
                  sizeUnit={'px'}
                  size={25}
                  color={'#FFFFFF'}
                  loading={loading}
                />
              </div>
            ) : (
              event.matches.length > 0 && (
                <LeagueEvent
                  matches={matches}
                  event={event}
                  onScoreChange={handleScoreChange.bind(this)}
                  onSaveMatches={handleSaveMatches.bind(this)}
                  onDropPlayer={handleDropPlayer.bind(this)}
                  admin
                />
              )
            )}
          </div>
        </div>
        <div id='bg'>
          <img src='/img/jace.jpg' alt='' />
        </div>
      </section>
    </React.Fragment>
  )
}

export default AdminEvent
