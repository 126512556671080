import React from 'react'
import { Link } from 'react-router-dom'

const StandingsTable = ({ league, playerStats = [] }) => {
  return (
    <table className='sm standings'>
      <thead>
        <tr>
          <th className='align-middle' colSpan='4' />
          <th className='align-middle matches' colSpan='6'>
            Matches
          </th>
          <th className='align-middle games' colSpan='3'>
            Games
          </th>
          <th className='align-middle events' colSpan={league.events.length}>
            Events
          </th>
        </tr>
        <tr>
          <th className='align-middle'>#</th>
          <th className='align-middle'>Name</th>
          <th className='align-middle'>Total Points</th>
          <th className='align-middle'>Toss Points</th>
          <th className='align-middle left-border matches'>Byes</th>
          <th className='align-middle matches'>Played</th>
          <th className='align-middle matches'>Won</th>
          <th className='align-middle matches'>Lost</th>
          <th className='align-middle matches'>Drawn</th>
          <th className='align-middle matches'>Win %</th>
          <th className='align-middle left-border games'>Won</th>
          <th className='align-middle games'>Lost</th>
          <th className='align-middle right-border games'>Win %</th>
          {league.events.map(event => (
            <th key={event.id} scope='row' className='align-middle events'>
              {event.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {playerStats.map((playerStats, index) => (
          <tr key={playerStats.player.id}>
            <td className='align-middle'>{index + 1}</td>
            <td className='align-middle'>
              <Link to={'/players/' + playerStats.player.id}>{playerStats.player.name}</Link>
            </td>
            <td className='align-middle'>{playerStats.totalPoints}</td>
            <td className='align-middle'>{playerStats.tossPoints}</td>
            <td className='align-middle left-border matches'>{playerStats.matchByes}</td>
            <td className='align-middle matches'>{playerStats.matchesPlayed}</td>
            <td className='align-middle matches'>{playerStats.matchesWon}</td>
            <td className='align-middle matches'>{playerStats.matchesLost}</td>
            <td className='align-middle matches'>{playerStats.matchesDrawn}</td>
            <td className='align-middle matches'>{playerStats.matchWinPercentage}%</td>
            <td className='align-middle left-border games'>{playerStats.gamesWon}</td>
            <td className='align-middle games'>{playerStats.gamesLost}</td>
            <td className='align-middle right-border games'>{playerStats.gameWinPercentage}%</td>
            {Object.entries(playerStats.eventPoints).map(([key, value]) => {
              return (
                <td key={key} className='align-middle events'>
                  {value}
                </td>
              )
            })}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default StandingsTable
