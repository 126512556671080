import React, { useContext } from 'react'
import Context from '../Context/Context'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const context = useContext(Context)
  return (
    <Route
      {...rest}
      render={props =>
        context.authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
