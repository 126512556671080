import React, { useState, useContext, useEffect } from 'react'
import StandingsTable from '../Components/StandingsTable'
import LeagueEvent from '../Components/LeagueEvent'
import Context from '../Context/Context'
import BeatLoader from 'react-spinners/BeatLoader'

export const Standings = props => {
  const context = useContext(Context)

  const [loading, setLoading] = useState(true)
  const [leagueId, setLeagueId] = useState(null)
  const [league, setLeague] = useState(null)
  const [playerStats, setPlayerStats] = useState(null)
  const [activeTab, setActiveTab] = useState('standings')

  const leagues = context.leagues

  useEffect(() => {
    if (leagues.length === 0) {
      context
        .getLeagues()
        .then(leagues => {
          const id = leagues[0] ? leagues[0].id : null
          setLeagueId(id)
        })
        .catch()
    } else if (!leagueId) {
      const id = leagues[0] ? leagues[0].id : null
      setLeagueId(id)
    } else {
      fetchData(leagueId)
    }
  }, [leagueId]) //will only on mount / when the leagues are loaded

  const fetchData = leagueId => {
    context
      .getStandings(leagueId)
      .then(leagueStats => {
        setLeague(leagueStats.league)
        setPlayerStats(leagueStats.playerStats)
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
      })
  }

  if (loading) {
    return (
      <React.Fragment>
        <h2>Standings</h2>
        <div className='loader'>
          <BeatLoader css={''} sizeUnit={'px'} size={25} color={'#FFFFFF'} loading={loading} />
        </div>
      </React.Fragment>
    )
  }

  const eventTabs = league.events.map(event => (
    <li
      key={event.id}
      onClick={() => setActiveTab(event.name)}
      active={activeTab === event.name ? '1' : '0'}
    >
      {event.name}
    </li>
  ))

  const leagueTabs = leagues.map(league => (
    <li
      key={league.id}
      onClick={() => setLeagueId(league.id)}
      active={leagueId === league.id ? '1' : '0'}
    >
      {league.name}
    </li>
  ))

  league.events.map(event => (
    <div className='standings-week-table' key={event.id} id={event.name}>
      <LeagueEvent event={event} matches={event.matches} leaguePlayers={league.players} />
    </div>
  ))

  return (
    <React.Fragment>
      <h2>Standings &mdash; {league ? league.name : null}</h2>
      <ul className='standings-tabs'>
        <li
          onClick={() => setActiveTab('standings')}
          active={activeTab === 'standings' ? '1' : '0'}
        >
          Standings
        </li>
        {eventTabs}
      </ul>
      {activeTab === 'standings' && (
        <StandingsTable league={league} playerStats={playerStats} id='standings' />
      )}
      {league.events
        .filter(e => e.name === activeTab)
        .map(event => (
          <LeagueEvent
            key={event.id}
            event={event}
            matches={event.matches}
            leaguePlayers={league.players}
          />
        ))}
      <h3>Previous Leagues</h3>
      <ul className='standings-tabs'>{leagueTabs}</ul>
    </React.Fragment>
  )
}

export default Standings
