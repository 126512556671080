import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

function Index() {
  return <App />
}

ReactDOM.render(<Index />, document.getElementById('root'))
