import React, { useContext, useEffect } from 'react'
import Context from '../Context/Context'
import BeatLoader from 'react-spinners/BeatLoader'
import { Link } from 'react-router-dom'

export const Players = props => {
  const context = useContext(Context)

  useEffect(() => {
    let players = context.players
    if (players.length === 0) {
      context.getPlayers()
    }
  }, []) //will only ever run once, when mounted

  let players = context.players
  const isLoading = !players || players.length === 0

  if (isLoading) {
    return (
      <React.Fragment>
        <h2>Players</h2>
        <div className='loader'>
          <BeatLoader css={''} sizeUnit={'px'} size={25} color={'#000000'} loading={isLoading} />
        </div>
      </React.Fragment>
    )
  } else {
    players = players.sort((p1, p2) => (p1.name.toUpperCase() > p2.name.toUpperCase() ? 1 : -1))

    return (
      <React.Fragment>
        <h2>Players</h2>
        <div className='playersDiv'>
          <ul className='playersList'>
            {context.players.map(player => (
              <li key={player.id}>
                <Link to={'/players/' + player.id} className='dark'>
                  {player.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </React.Fragment>
    )
  }
}

export default Players
