import React, { useContext, useEffect, useState } from 'react'
import Context from '../Context/Context'
import BeatLoader from 'react-spinners/BeatLoader'
import { Link } from 'react-router-dom'
import Nav from '../Layouts/Nav'

export const Player = props => {
  const context = useContext(Context)
  const [playerStats, setPlayerStats] = useState(null)

  const { playerId } = props.match.params

  useEffect(() => {
    context
      .getPlayer(playerId)
      .then(p => {
        setPlayerStats(p)
      })
      .catch(error => {
        console.log(error)
      })
  }, [playerId]) //will only ever run once, when mounted

  const computeTotals = () => {
    //add up all wins/losses/etc
    let tally = playerStats.leagueStats.reduce((acc, ls) => {
      acc.matchesPlayed = (acc.matchesPlayed || 0) + ls.matchesPlayed
      acc.matchesWon = (acc.matchesWon || 0) + ls.matchesWon
      acc.matchesLost = (acc.matchesLost || 0) + ls.matchesLost
      acc.matchesDrawn = (acc.matchesDrawn || 0) + ls.matchesDrawn
      acc.gamesWon = (acc.gamesWon || 0) + ls.gamesWon
      acc.gamesLost = (acc.gamesLost || 0) + ls.gamesLost
      return acc
    }, {})

    //figure out win percentages
    tally.matchWinPercentage =
      tally.matchesWon / tally.matchesPlayed === 0
        ? 0
        : ((tally.matchesWon / tally.matchesPlayed) * 100).toFixed(0)

    tally.gameWinPercentage =
      tally.gamesWon + tally.gamesLost === 0
        ? 0
        : ((tally.gamesWon / (tally.gamesWon + tally.gamesLost)) * 100).toFixed(0)

    return tally
  }

  // let players = context.players
  const isLoading = !playerStats

  if (isLoading) {
    return (
      <React.Fragment>
        <Nav />
        <section className='section-player'>
          <div className='loader'>
            <BeatLoader css={''} sizeUnit={'px'} size={25} color={'#FFFFFF'} loading={isLoading} />
          </div>

          <div id='bg'>
            <img src='/img/jace.jpg' alt='' />
          </div>
        </section>
      </React.Fragment>
    )
  } else {
    //sort leagues by ID, newest to the top
    playerStats.leagueStats.sort((p1, p2) => (p1.league.id > p2.league.id ? -1 : 1))
    playerStats.opponentStats.sort((p1, p2) => (p1.player.name < p2.player.name ? -1 : 1))
    const tally = computeTotals()

    return (
      <React.Fragment>
        <Nav />
        <section className='section-player'>
          <div className='row'>
            <h1>{playerStats.player.name}</h1>
          </div>

          <div className='row'>
            <h3>League Stats</h3>
            <table className='sm standings'>
              <thead>
                <tr>
                  <th className='align-middle' />
                  <th className='align-middle' colSpan='5'>
                    Matches
                  </th>
                  <th className='align-middle' colSpan='3'>
                    Games
                  </th>
                </tr>
                <tr>
                  <th className='align-middle'>Name</th>
                  <th className='align-middle'>Played</th>
                  <th className='align-middle'>Won</th>
                  <th className='align-middle'>Lost</th>
                  <th className='align-middle'>Drawn</th>
                  <th className='align-middle'>Win %</th>
                  <th className='align-middle left-border'>Won</th>
                  <th className='align-middle'>Lost</th>
                  <th className='align-middle right-border'>Win %</th>
                </tr>
              </thead>
              <tbody>
                {playerStats.leagueStats.map((leagueStats, index) => (
                  <tr key={leagueStats.league.id}>
                    <td className='align-middle'>{leagueStats.league.name}</td>
                    <td className='align-middle'>{leagueStats.matchesPlayed}</td>
                    <td className='align-middle'>{leagueStats.matchesWon}</td>
                    <td className='align-middle'>{leagueStats.matchesLost}</td>
                    <td className='align-middle'>{leagueStats.matchesDrawn}</td>
                    <td className='align-middle'>{leagueStats.matchWinPercentage}%</td>
                    <td className='align-middle left-border'>{leagueStats.gamesWon}</td>
                    <td className='align-middle'>{leagueStats.gamesLost}</td>
                    <td className='align-middle right-border'>{leagueStats.gameWinPercentage}%</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr key='totals'>
                  <td className='align-middle'>Totals</td>
                  <td className='align-middle'>{tally.matchesPlayed}</td>
                  <td className='align-middle'>{tally.matchesWon}</td>
                  <td className='align-middle'>{tally.matchesLost}</td>
                  <td className='align-middle'>{tally.matchesDrawn}</td>
                  <td className='align-middle'>{tally.matchWinPercentage}%</td>
                  <td className='align-middle left-border'>{tally.gamesWon}</td>
                  <td className='align-middle'>{tally.gamesLost}</td>
                  <td className='align-middle right-border'>{tally.gameWinPercentage}%</td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div className='row'>
            <h3>Opponent Stats</h3>
            <table className='sm standings'>
              <thead>
                <tr>
                  <th className='align-middle' />
                  <th className='align-middle' colSpan='5'>
                    Matches
                  </th>
                  <th className='align-middle' colSpan='3'>
                    Games
                  </th>
                </tr>
                <tr>
                  <th className='align-middle'>Name</th>
                  <th className='align-middle'>Played</th>
                  <th className='align-middle'>Won</th>
                  <th className='align-middle'>Lost</th>
                  <th className='align-middle'>Drawn</th>
                  <th className='align-middle'>Win %</th>
                  <th className='align-middle left-border'>Won</th>
                  <th className='align-middle'>Lost</th>
                  <th className='align-middle right-border'>Win %</th>
                </tr>
              </thead>
              <tbody>
                {playerStats.opponentStats.map(opponentStats => (
                  <tr key={opponentStats.player.id}>
                    <td className='align-middle'>
                      <Link to={'/players/' + opponentStats.player.id}>
                        {opponentStats.player.name}
                      </Link>
                    </td>
                    <td className='align-middle'>{opponentStats.matchesPlayed}</td>
                    <td className='align-middle'>{opponentStats.matchesWon}</td>
                    <td className='align-middle'>{opponentStats.matchesLost}</td>
                    <td className='align-middle'>{opponentStats.matchesDrawn}</td>
                    <td className='align-middle'>{opponentStats.matchWinPercentage}%</td>
                    <td className='align-middle left-border'>{opponentStats.gamesWon}</td>
                    <td className='align-middle'>{opponentStats.gamesLost}</td>
                    <td className='align-middle right-border'>
                      {opponentStats.gameWinPercentage}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div id='bg'>
            <img src='/img/jace.jpg' alt='' />
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default Player
