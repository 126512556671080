import React from 'react'
import Nav from './Nav'

const Header = () => {
    return (
      <React.Fragment>
        <a id="btn-back-to-top" href="#top"><i className="icon ion-md-arrow-dropup-circle"></i></a>
        <header id="top" className="wp-top">
          <Nav />
          <div className="hero-text-box">
              <h1>Sealed League</h1>
              <a className="btn btn-full" href="#standings">Standings</a>
              <a className="btn btn-ghost" href="#rules">Rules</a>
          </div>
        </header>
      </React.Fragment>
      )
}

export default Header
