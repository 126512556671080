import React from 'react'

const Rules = () => (
  <React.Fragment>
    
    <h2>Rules</h2>

    <div className="rules">
      <div className="box">
        <h3>Deck Construction</h3>
        <ol>
          <li>First gathering each player will purchase boosters from the current set/block</li>
          <li>Deck construction will be allowed during the first 30 minutes of each gathering</li>
          <li>Decks shall be a minimum of 40 cards</li>
          <li>There is no limit to the number of copies of a card that can be in your deck</li>
          <li>
            Each gathering after the first, players will buy 1 booster pack from the current block
            (to add to their existing pool) and $5 in store credit to add to the prize pool (See
            Prize Pool below)
          </li>
          <li>Packs will be provided by the store, players shall not bring their own packs</li>
          <li>
            Deck modifications can be made at any time during the night, either before, during or
            after matches (obviously, not in the middle of a game)
          </li>
        </ol>
      </div>
      <div className="box">
        <h3>Match/Game Play</h3>
        <ol>
          <li>Players will play 3 matches, best 2 out of 3</li>
          <li>Each match will last 50 minutes plus 5 additional turns</li>
          <li>Side-boarding between games in a match is allowed</li>
          <li>Main deck modifications between matches is allowed</li>
          <li>
            Player matchups will be made up using ScheduLeague.com, however changes to matchups may
            be made based on the players who show up
          </li>
          <li>
            Players cannot play each other more than once a day, and cannot play each other more
            than twice in the league
          </li>
          <li>
            A draw can only occur if time runs out in the match (Players cannot agree to draw)
          </li>
        </ol>
      </div>
      <div className="box">
        <h3>Dropping/Leaving the League</h3>
        <p>
          If , for any reason, a player decides to drop or leave the league, the player MUST notify the
          league organizer prior to departure from the league. Players will be allowed to take the cards
          in their collectors box with them, but any packs in the prize pool will remain in the prize
          pool.
        </p>
        <p>
          If a player fails to notify the league organizer of their departure, that player will forfeit
          all cards in their collectors box into the prize pool and will not be allowed to retrieve
          their collectors box or any cards they have purchased while participating in the League.
        </p>
        <p>
          A player is considered to have dropped from or left the league if they miss 3 consecutive
          gatherings without notifying the league organizer.
        </p>
      </div>
      <div className="box">
        <h3>Schedule</h3>
        <ol>
          <li>Players will meet approximately every 2nd Sunday from 1:00 to 4:00</li>
          <li>League will run for 5 meetings (approx. 3 months)</li>
        </ol>
      </div>
      <div className="box">
        <h3>Rulings</h3>
        <ol>
          <li>Players may announce a rule dispute by calling a Judge and raising their hand</li>
          <li>A Judge or Rules Advisor will make the ruling (normally the league organizer)</li>
        </ol>
      </div>
      <div className="box">
        <h3>Points</h3>
        <ol>
          <li>Each game win is 1 point</li>
          <li>Each match win is 1 point</li>
          <li>A draw will award a match point to both players (1 point each)</li>
        </ol>
        <p>Points from a players worst week will not be counted. (Toss week/points)</p>
      </div>
      <div className="box">
        <h3>Additional Boosters</h3>
        <p>
          Players will be allowed to purchase 1 additional booster from the current block to add to
          their card pool for every 3 match loses.
        </p>
      </div>
      <div className="box">
        <h3>Deck Storage</h3>
        <p>
          At the end of the night, each player will place all cards they own from the league into their
          collector box, and turn all cards in to the league organizer/host. Cards and boxes will be
          kept at the host location.
        </p>
      </div>
      <div className="box">
        <h3>Prize Pool</h3>
        <p>
          The prize pool will consist of store credit purchased by players during the League ($5 from
          each player on weeks 2-5). This will be distributed at the end of the league based on players
          standings. (See Prize Distribution below)
        </p>
      </div>
      <div className="box">
        <h3>Prize Distribution</h3>
        (% of packs in prize pool, rounded down)
        <ul>
          <li>1st - 25%</li>
          <li>2nd - 20%</li>
          <li>3rd - 15%</li>
          <li>4th - 12.5%</li>
          <li>5th - 10%</li>
          <li>6th - 7.5%</li>
          <li>7th - 5%</li>
          <li>8th - 5%</li>
        </ul>
        <p>This is the most common example of the prize payout but may be modified based on # of players in
        the League.</p>
      </div>
    </div>
  </React.Fragment>
)

export default Rules
