import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Home, NotFound, Admin, AdminLeague, AdminEvent, Login, Player } from './Containers/index'
import PrivateRoute from './Components/PrivateRoute'
import Toasts from './Components/Toasts'
import { ToastContainer } from 'react-toastify'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSearch,
  faUser,
  faLock,
  faTrashAlt,
  faDollarSign,
  faPlus,
  faAsterisk,
} from '@fortawesome/free-solid-svg-icons'
import State from './Context/State'

library.add([faSearch, faUser, faLock, faTrashAlt, faDollarSign, faPlus, faAsterisk])

const App = props => {
  return (
    <State>
      <Router>
        <ToastContainer />
        <Toasts />

        <Switch>
          <Route exact path='/' component={Home} name='Home' />
          <Route path='/login' component={Login} />
          <Route exact path='/players/:playerId' component={Player} />
          <PrivateRoute exact path='/admin' component={Admin} />
          <PrivateRoute exact path='/admin/league/:leagueId' component={AdminLeague} />
          <PrivateRoute
            exact
            path='/admin/league/:leagueId/event/:eventId'
            component={AdminEvent}
          />
          <Route path='*' component={NotFound} />
        </Switch>
      </Router>
    </State>
  )
}

export default App
