import React, { useContext, useState } from 'react'
import axios from 'axios'
import Context from '../Context/Context'
import Nav from '../Layouts/Nav'

export const Login = props => {
  const context = useContext(Context)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const handleFormSubmit = e => {
    e.preventDefault()

    axios
      .post(process.env.REACT_APP_LOGIN_URL, {
        email: email,
        password: password,
      })
      .then(response => {
        if (response.status === 200) {
          context.login('Bearer ' + response.data.token)
          const { from } = props.location.state || { from: { pathname: '/' } }
          props.history.push(from)
        }
      })
      .catch(error => {
        const response = error.response
        if (response.status === 401 || response.status === 403) {
          context.logout()
          setError('Invalid email or password')
          return
        } else if (response.status === 500) {
          context.logout()
          setError('Something happened... check the server logs.')
          return
        }
        setError(error.message)
      })
  }

  return (
    <React.Fragment>
      <Nav />
      <section className='section-login'>
        <div className='row'>
          <h1>Login</h1>
        </div>
        <div className='row align-middle'>
          <form className='login-form' onSubmit={e => handleFormSubmit(e)}>
            <span>Email: </span>
            <input
              type='text'
              placeholder='Enter email'
              name='email'
              onChange={e => setEmail(e.target.value)}
              value={email}
              required
            />
            <span></span>
            <span>Password: </span>
            <input
              type='password'
              placeholder='Password'
              name='password'
              onChange={e => setPassword(e.target.value)}
              value={password}
              required
            />
            <span></span>
            <span></span>
            <button className='primary' type='submit'>
              Login
            </button>
            <span></span>
            <span></span>
            {error && <div class='login-error'>{error}</div>}
          </form>
        </div>
        <div id='bg'>
          <img src='/img/jace.jpg' alt='' />
        </div>
      </section>
    </React.Fragment>
  )
}

export default Login
