import React, { useContext } from 'react'
import Context from '../Context/Context'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { NOTIFICATION_TYPE_SUCCESS, NOTIFICATION_TYPE_ERROR } from '../Constants/notification-types'

const Toasts = props => {
  const context = useContext(Context)
  const notification = context.notification
  if (notification) {
    if (notification.type === NOTIFICATION_TYPE_ERROR) {
      console.log(notification)
      toast.error(<Notification notification={notification} />, {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } else if (notification.type === NOTIFICATION_TYPE_SUCCESS) {
      toast.success(<Notification notification={notification} />, {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    }
    context.clearNotification()
  }
  return <React.Fragment />
}

const Notification = ({ notification }) => (
  <React.Fragment>
    <strong>{notification.message}</strong>
    {notification.error && (
      <React.Fragment>
        <br />
        <small>{notification.error.status}</small>
        <br />
        <small>{notification.error.message}</small>
      </React.Fragment>
    )}
  </React.Fragment>
)

export default Toasts
