import React from 'react'
import { Standings, Players, Rules } from './index'
import Header from '../Layouts/Header'

const Home = () => (
  <React.Fragment>
    <Header />

    <section className='section-about' id='about'>
      <div className='row'>
        <h2>About</h2>
        <p className='long-copy'>
          The Sealed Deck League is designed as a semi-casual format with players using booster
          packs from the current set/block to build a 40-card deck. Players will meet twice a month
          and will be allowed to add cards from boosters purchased during each meeting to improve
          their deck. Points will be earned for game and match wins, and players will receive
          boosters as prizes based on the final standings.
        </p>
      </div>
    </section>

    <section className='section-standings' id='standings'>
      <div className='row'>
        <Standings />
      </div>
    </section>

    <section className='section-players' id='players'>
      <div className='row'>
        <Players />
      </div>
    </section>

    <section className='section-rules' id='rules'>
      <div className='row'>
        <Rules />
      </div>
    </section>
  </React.Fragment>
)

export default Home
