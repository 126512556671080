import React from 'react'
import Dialog from '../Components/Dialog'
import PlayerList from './PlayerList'

class AddPlayerDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      playersPerPage: 10,
    }
  }

  handlePlayerNameChangeEvent(event) {
    this.setState({ [event.target.id]: event.target.value })
  }

  render() {
    const { players, league } = this.props
    const { playersPerPage } = this.state

    if (!players) return null

    let modalProps = {
      show: this.props.show,
      onHide: this.props.onClose,
    }

    return (
      <Dialog
        {...modalProps}
        content={
          <React.Fragment>
            <h2>Add Player(s)</h2>

            <PlayerList
              players={players}
              playersPerPage={playersPerPage}
              league={league}
              onAddPlayer={this.props.onAddPlayer}
              admin
            />
          </React.Fragment>
        }
        onHide={this.props.onClose}
        footer={
          <React.Fragment>
            <button className='primary' onClick={this.props.onClose}>
              Close
            </button>
          </React.Fragment>
        }
      />
    )
  }
}

export default AddPlayerDialog
