import React, { useState, useEffect, useReducer } from 'react'
import axios from 'axios'
import Context from './Context'
import { PlayerReducer, CREATE_PLAYER, SET_PLAYERS } from '../Reducers/PlayerReducer'
import {
  LeagueReducer,
  SET_LEAGUES,
  ADD_LEAGUE,
  DELETE_LEAGUE,
  ADD_EVENT,
  DELETE_EVENT,
  ADD_PLAYER,
  REMOVE_PLAYER,
  UPDATE_PLAYER,
  UPDATE_MATCHES,
} from '../Reducers/LeagueReducer'
import { authHeader } from '../Helpers/authHeader'
import { NOTIFICATION_TYPE_SUCCESS, NOTIFICATION_TYPE_ERROR } from '../Constants/notification-types'

const State = props => {
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(sessionStorage.getItem('authenticated')) === true || false,
  )
  const [notification, setNotification] = useState(null)

  const [leagueState, leagueDispatch] = useReducer(LeagueReducer, {
    leagues: [],
  })
  const [playerState, playerDispatch] = useReducer(PlayerReducer, { players: [] })

  useEffect(() => {
    sessionStorage.setItem('authenticated', authenticated)
  }, [authenticated])

  const login = authHeader => {
    sessionStorage.setItem('authHeader', authHeader)
    setAuthenticated(true)
  }

  const logout = url => {
    // remove user from local storage to log user out
    sessionStorage.removeItem('authHeader')
    setAuthenticated(false)
  }

  const getPlayers = () => {
    const options = {
      method: 'GET',
      url: process.env.REACT_APP_API + '/players',
    }

    axios(options)
      .then(response => {
        const players = response.data
        playerDispatch({ type: SET_PLAYERS, players })
      })
      .catch(error => {
        notify({ message: 'Error fetching players list', type: NOTIFICATION_TYPE_ERROR, error })
      })
  }

  const getPlayer = playerId => {
    return new Promise((resolve, reject) => {
      const options = {
        method: 'GET',
        url: process.env.REACT_APP_API + '/players/' + playerId,
      }

      axios(options)
        .then(response => {
          const player = response.data
          resolve(player)
        })
        .catch(error => {
          notify({
            message: 'Error fetching player id: ' + playerId,
            type: NOTIFICATION_TYPE_ERROR,
            error,
          })
          reject(error)
        })
    })
  }

  const createPlayer = playerName => {
    const options = {
      method: 'POST',
      headers: authHeader(),
      data: {
        name: playerName,
      },
      url: process.env.REACT_APP_API + '/players/',
    }

    axios(options)
      .then(response => {
        const player = response.data.player
        playerDispatch({ type: CREATE_PLAYER, player })
      })
      .then(response => {
        notify({ message: 'Player created', type: NOTIFICATION_TYPE_SUCCESS })
      })
      .catch(error => {
        notify({ message: 'Error creating player', type: NOTIFICATION_TYPE_ERROR, error })
      })
  }

  const getLeagues = () => {
    return new Promise((resolve, reject) => {
      const options = {
        method: 'GET',
        url: process.env.REACT_APP_API + '/leagues',
      }

      axios(options)
        .then(response => {
          const leagues = response.data
          leagueDispatch({ type: SET_LEAGUES, leagues: leagues })
          resolve(leagues)
        })
        .catch(error => {
          notify({ message: 'Failed to get leagues', type: NOTIFICATION_TYPE_ERROR, error })
          reject(error)
        })
    })
  }

  const addLeague = name => {
    const options = {
      method: 'POST',
      headers: authHeader(),
      data: {
        name: name,
      },
      url: process.env.REACT_APP_API + '/leagues',
    }

    axios(options)
      .then(response => {
        const league = response.data.league
        leagueDispatch({ type: ADD_LEAGUE, league })
      })
      .then(response => {
        notify('League: ' + name + ' created', NOTIFICATION_TYPE_SUCCESS)
      })
      .catch(error => {
        notify({ message: 'Error adding league', type: NOTIFICATION_TYPE_ERROR, error })
      })
  }

  const deleteLeague = leagueId => {
    const options = {
      method: 'DELETE',
      headers: authHeader(),
      data: {
        id: leagueId,
      },
      url: process.env.REACT_APP_API + '/leagues/',
    }
    axios(options)
      .then(response => {
        leagueDispatch({ type: DELETE_LEAGUE, leagueId })
      })
      .then(response => {
        notify({ message: 'League Removed', type: NOTIFICATION_TYPE_SUCCESS })
      })
      .catch(error => {
        notify({ message: 'Error deleting league', type: NOTIFICATION_TYPE_ERROR, error })
      })
  }

  const getLeague = leagueId => {
    const options = {
      method: 'GET',
      headers: authHeader(),
      url: process.env.REACT_APP_API + '/leagues/' + leagueId,
    }

    //if no leagues are loaded, get all the metadata then fetch the rquested league
    if (leagueState.leagues.length === 0) {
      getLeagues()
        .then(response => {
          return axios(options)
        })
        .then(response => {
          const league = response.data.league
          leagueDispatch({ type: ADD_LEAGUE, league })
        })
        .catch(error => {
          notify({ message: 'Error getting league', type: NOTIFICATION_TYPE_ERROR, error })
        })
    } else {
      //leagues already populated, just get the one
      axios(options)
        .then(response => {
          const league = response.data.league
          leagueDispatch({ type: ADD_LEAGUE, league })
        })
        .catch(error => {
          notify({ message: 'Error getting league', type: NOTIFICATION_TYPE_ERROR, error })
        })
    }
  }

  const addEvent = (leagueId, eventName) => {
    const options = {
      method: 'POST',
      headers: authHeader(),
      data: {
        name: eventName,
      },
      url: process.env.REACT_APP_API + '/leagues/' + leagueId + '/event/',
    }

    axios(options)
      .then(response => {
        const event = response.data.event
        leagueDispatch({ type: ADD_EVENT, event, leagueId })
      })
      .then(response => {
        notify({ message: 'Event: ' + eventName + ' created', type: NOTIFICATION_TYPE_SUCCESS })
      })
      .catch(error => {
        notify({ message: 'Error adding event', type: NOTIFICATION_TYPE_ERROR, error })
      })
  }

  const deleteEvent = (leagueId, eventId) => {
    const options = {
      method: 'DELETE',
      headers: authHeader(),
      data: {
        id: eventId,
      },
      url: process.env.REACT_APP_API + '/leagues/' + leagueId + '/event/',
    }

    axios(options)
      .then(response => {
        leagueDispatch({ type: DELETE_EVENT, eventId, leagueId })
      })
      .then(response => {
        notify({ message: 'Event removed', type: NOTIFICATION_TYPE_SUCCESS })
      })
      .catch(error => {
        notify({ message: 'Error deleting event', type: NOTIFICATION_TYPE_ERROR, error })
      })
  }

  const addPlayer = (leagueId, playerId) => {
    const options = {
      method: 'POST',
      headers: authHeader(),
      data: {
        id: playerId,
      },
      url: process.env.REACT_APP_API + '/leagues/' + leagueId + '/player/',
    }

    axios(options)
      .then(response => {
        const player = response.data.player
        leagueDispatch({ type: ADD_PLAYER, player, leagueId })
      })
      .then(response => {
        notify({ message: 'Player added', type: NOTIFICATION_TYPE_SUCCESS })
      })
      .catch(error => {
        notify({ message: 'Error adding player', type: NOTIFICATION_TYPE_ERROR, error })
      })
  }

  const removePlayer = (leagueId, playerId) => {
    const options = {
      method: 'DELETE',
      headers: authHeader(),
      data: {
        id: playerId,
      },
      url: process.env.REACT_APP_API + '/leagues/' + leagueId + '/player/',
    }

    axios(options)
      .then(response => {
        leagueDispatch({ type: REMOVE_PLAYER, playerId, leagueId })
      })
      .then(response => {
        notify({ message: 'Player removed', type: NOTIFICATION_TYPE_SUCCESS })
      })
      .catch(error => {
        notify({ message: 'Error removing player', type: NOTIFICATION_TYPE_ERROR, error })
      })
  }

  const purchasePack = (leagueId, playerId) => {
    const options = {
      method: 'POST',
      headers: authHeader(),
      data: {
        playerId,
      },
      url: process.env.REACT_APP_API + '/leagues/' + leagueId + '/purchasePack',
    }

    axios(options)
      .then(response => {
        const player = response.data.player
        leagueDispatch({ type: UPDATE_PLAYER, player, leagueId })
      })
      .then(response => {
        notify({ message: 'Pack purchased', type: NOTIFICATION_TYPE_SUCCESS })
      })
      .catch(error => {
        notify({ message: 'Error purchasing pack', type: NOTIFICATION_TYPE_ERROR, error })
      })
  }

  const getStandings = leagueId => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API + '/standings/' + leagueId)
        .then(response => {
          resolve({
            league: response.data.league,
            leagueId: response.data.league.id,
            playerStats: response.data.playerStats,
          })
        })
        .catch(error => {
          notify({ message: 'Error fetching standings', type: NOTIFICATION_TYPE_ERROR, error })
          reject(error)
        })
    })
  }

  const generatePairings = (eventId, leagueId, playerIds, rounds) => {
    return new Promise((resolve, reject) => {
      const options = {
        method: 'POST',
        headers: authHeader(),
        data: {
          playerIds,
          nbRounds: rounds,
        },
        url:
          process.env.REACT_APP_API +
          '/leagues/' +
          leagueId +
          '/event/' +
          eventId +
          '/generatePairings',
      }

      axios(options)
        .then(response => {
          const event = response.data.event
          leagueDispatch({ type: UPDATE_MATCHES, event, leagueId })
          return response
        })
        .then(response => {
          notify({
            message: 'Pairings generated. (' + response.data.timesGenerated + ')',
            type: NOTIFICATION_TYPE_SUCCESS,
          })
          resolve(response.data.event)
        })
        .catch(error => {
          notify({ message: 'Error generating pairings', type: NOTIFICATION_TYPE_ERROR, error })
          reject(error)
        })
    })
  }

  const dropPlayer = (eventId, leagueId, playerId, round) => {
    return new Promise((resolve, reject) => {
      const options = {
        method: 'POST',
        headers: authHeader(),
        data: {
          playerId,
          round,
        },
        url:
          process.env.REACT_APP_API + '/leagues/' + leagueId + '/event/' + eventId + '/dropPlayer',
      }

      axios(options)
        .then(response => {
          const event = response.data.event
          leagueDispatch({ type: UPDATE_MATCHES, event, leagueId })
          return response
        })
        .then(response => {
          notify({ message: 'Player dropped', type: NOTIFICATION_TYPE_SUCCESS })
          resolve(response.data.event)
        })
        .catch(error => {
          notify({ message: 'Error dropping player', type: NOTIFICATION_TYPE_ERROR, error })
          reject(error)
        })
    })
  }

  const saveScores = (leagueId, eventId, matches) => {
    return new Promise((resolve, reject) => {
      const options = {
        method: 'POST',
        headers: authHeader(),
        data: {
          matches,
        },
        url:
          process.env.REACT_APP_API + '/leagues/' + leagueId + '/event/' + eventId + '/saveScores/',
      }

      axios(options)
        .then(response => {
          getLeague(leagueId)
          notify({ message: 'Scores saved', type: NOTIFICATION_TYPE_SUCCESS })
        })
        .catch(error => {
          notify({ message: 'Error saving scores', type: NOTIFICATION_TYPE_ERROR, error })
          reject(error)
        })
    })
  }

  const notify = ({ message, type, error }) => {
    setNotification({ message, type, error })
  }

  const clearNotification = () => {
    setNotification(null)
  }

  return (
    <Context.Provider
      value={{
        authenticated,
        leagues: leagueState.leagues,
        players: playerState.players,
        notification,

        //functions
        login,
        logout,
        getPlayers,
        getPlayer,
        getLeagues,
        addLeague,
        createPlayer,
        deleteLeague,
        getLeague,
        addEvent,
        deleteEvent,
        addPlayer,
        removePlayer,
        purchasePack,
        getStandings,
        generatePairings,
        dropPlayer,
        saveScores,

        //notifications
        notify,
        clearNotification,
      }}
    >
      {props.children}
    </Context.Provider>
  )
}

export default State
