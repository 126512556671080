import React, { Component } from 'react'

class NotFound extends Component {
  render() {
    return (
      <React.Fragment>
        <h1>
          404 <small className='text-muted'>Page Not Found</small>
        </h1>
      </React.Fragment>
    )
  }
}

export default NotFound
