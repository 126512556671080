import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Context from '../Context/Context'
import Dialog from '../Components/Dialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Nav from '../Layouts/Nav'

const Admin = props => {
  const context = useContext(Context)

  const [leagueName, setLeagueName] = useState('')
  const [playerName, setPlayerName] = useState('')
  const [deleteDialogShow, setDeleteDialogShow] = useState(false)
  const [deleteDialogLeagueId, setDeleteDialogLeagueId] = useState(null)

  useEffect(() => {
    let players = context.players
    let leagues = context.leagues
    if (players.length === 0) context.getPlayers()
    if (leagues.length === 0) context.getLeagues()
  }, []) //will only ever run once, when mounted

  const handleSubmitPlayer = e => {
    e.preventDefault()
    context.createPlayer(playerName)
    setPlayerName('')
  }

  const handleSubmitLeague = e => {
    e.preventDefault()
    context.addLeague(leagueName)
    setLeagueName('')
  }

  const handleDeleteLeague = leagueId => {
    setDeleteDialogShow(true)
    setDeleteDialogLeagueId(leagueId)
  }

  const confirmDelete = e => {
    context.deleteLeague(deleteDialogLeagueId)
    closeDialog(e)
  }

  const closeDialog = e => {
    setDeleteDialogShow(false)
  }

  const players = context.players
  const leagues = context.leagues

  if (players) players.sort((p1, p2) => (p1.name.toUpperCase() > p2.name.toUpperCase() ? 1 : -1))

  return (
    <React.Fragment>
      <Nav />
      <section className='section-admin'>
        <div className='row'>
          <h1>Admin</h1>
        </div>

        <div className='row'>
          <h3>Leagues</h3>
        </div>

        <div className='row'>
          <div className='col span-1-of-2'>
            <form className='add-league-form' onSubmit={e => handleSubmitLeague(e)}>
              <input
                placeholder='League name'
                id='leagueName'
                value={leagueName}
                onChange={e => setLeagueName(e.target.value)}
                required
                autoComplete='off'
              />
              <button className='primary' type='submit'>
                Add League
              </button>
            </form>
          </div>
          <div className='col span-1-of-2'>
            {deleteDialogShow && (
              <Dialog
                show={deleteDialogShow}
                title='Delete League?'
                content={'Are you sure you wish the delete this league and all its contents?'}
                onHide={e => closeDialog(e)}
                footer={
                  <React.Fragment>
                    <button className='primary' onClick={e => closeDialog(e)}>
                      Cancel
                    </button>
                    <button className='danger' onClick={e => confirmDelete(e)}>
                      Yes
                    </button>
                  </React.Fragment>
                }
                confirmButtonText='Yes, delete'
              />
            )}
            <table className='sm'>
              <tbody>
                {leagues.map(league => (
                  <tr key={league.id}>
                    <td className='grow'>
                      <Link to={'/admin/league/' + league.id}>{league.name}</Link>
                    </td>
                    <td>
                      <button
                        onClick={() => handleDeleteLeague(league.id)}
                        className='sm danger'
                        id={league.id}
                      >
                        <FontAwesomeIcon icon='trash-alt' />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className='row'>
          <h3>Players</h3>
        </div>
        <div className='row'>
          <div className='col span-2-of-4'>
            <form className='add-player-form' onSubmit={e => handleSubmitPlayer(e)}>
              <input
                placeholder='Player name'
                id='playerName'
                value={playerName}
                onChange={e => setPlayerName(e.target.value)}
                required
                autoComplete='off'
              />
              <button className='primary' type='submit'>
                Add Player
              </button>
            </form>
          </div>

          <div className='col span-1-of-4'>
            <table className='sm'>
              <tbody>
                {players &&
                  players.slice(0, players.length / 2).map(player => (
                    <tr key={player.id}>
                      <td className='grow'>
                        <Link to={'/players/' + player.id}>{player.name}</Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className='col span-1-of-4'>
            <table className='sm'>
              <tbody>
                {players &&
                  players.slice(players.length / 2).map(player => (
                    <tr key={player.id}>
                      <td className='grow'>
                        <Link to={'/players/' + player.id}>{player.name}</Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        <div id='bg'>
          <img src='/img/jace.jpg' alt='' />
        </div>
      </section>
    </React.Fragment>
  )
}

export default Admin
