export const SET_LEAGUES = 'SET_LEAGUES'
export const ADD_LEAGUE = 'ADD_LEAGUE'
export const GET_LEAGUE = 'GET_LEAGUE'
export const DELETE_LEAGUE = 'DELETE_LEAGUE'

export const ADD_EVENT = 'ADD_EVENT'
export const DELETE_EVENT = 'DELETE_EVENT'
export const ADD_PLAYER = 'ADD_PLAYER'
export const REMOVE_PLAYER = 'REMOVE_PLAYER'
export const UPDATE_PLAYER = 'UPDATE_PLAYER'
export const UPDATE_MATCHES = 'UPDATE_MATCHES'

const setLeagues = (leagues, state) => {
  return { leagues: [...leagues] }
}

const addLeague = (league, state) => {
  const inArray = state.leagues.find(l => league.id === l.id)
  return {
    //if it's already in there, replace it, otherwise add it
    leagues: inArray
      ? state.leagues.map(l => {
          if (league.id === l.id) return league
          else return l // Leave every other item unchanged
        })
      : [league, ...state.leagues],
  }
}

const deleteLeague = (leagueId, state) => {
  const index = state.leagues.findIndex(league => league.id === leagueId)

  return {
    leagues: [
      ...state.leagues.slice(0, index), //find index of item
      ...state.leagues.slice(index + 1),
    ],
  }
}

const addEvent = (action, state) => {
  const { event, leagueId } = action
  return {
    leagues: state.leagues.map(league => {
      if (league.id === leagueId) {
        return {
          ...league, // copy the existing item
          events: [...league.events, event],
        }
      }
      return league // Leave every other item unchanged
    }),
  }
}

const deleteEvent = (action, state) => {
  const { eventId, leagueId } = action
  return {
    leagues: state.leagues.map(league => {
      if (league.id === leagueId) {
        return {
          ...league, // copy the existing item
          events: league.events.filter(event => {
            if (event.id === eventId) return false
            return true
          }),
        }
      }
      return league // Leave every other item unchanged
    }),
  }
}

const addPlayer = (action, state) => {
  const { player, leagueId } = action
  return {
    leagues: state.leagues.map((league, index) => {
      if (league.id === leagueId) {
        return {
          ...league, // copy the existing item
          players: [...league.players, player],
        }
      }
      return league // Leave every other item unchanged
    }),
  }
}

const removePlayer = (action, state) => {
  const { playerId, leagueId } = action
  return {
    leagues: state.leagues.map(league => {
      if (league.id === leagueId) {
        return {
          ...league, // copy the existing item
          players: league.players.filter(player => {
            if (player.id === playerId) return false
            return true
          }),
        }
      }
      return league // Leave every other item unchanged
    }),
  }
}

const updatePlayer = (action, state) => {
  const { player, leagueId } = action
  return {
    leagues: state.leagues.map(league => {
      if (league.id === leagueId) {
        return {
          ...league, // copy the existing item
          players: league.players.map(p => {
            if (p.id === player.id) return player
            else return p
          }),
        }
      }
      return league // Leave every other item unchanged
    }),
  }
}

const updateMatches = (action, state) => {
  const { event, leagueId } = action
  return {
    leagues: state.leagues.map(league => {
      if (league.id === leagueId) {
        return {
          ...league, // copy the existing item
          events: league.events.map(e => {
            if (e.id === event.id) {
              return {
                ...event,
                matches: event.matches,
                rounds: event.rounds,
              }
            }
            return e
          }),
        }
      }
      return league // Leave every other item unchanged
    }),
  }
}

export const LeagueReducer = (state, action) => {
  switch (action.type) {
    case SET_LEAGUES:
      return setLeagues(action.leagues, state)
    case ADD_LEAGUE:
      return addLeague(action.league, state)
    case DELETE_LEAGUE:
      return deleteLeague(action.leagueId, state)
    case ADD_EVENT:
      return addEvent(action, state)
    case DELETE_EVENT:
      return deleteEvent(action, state)
    case ADD_PLAYER:
      return addPlayer(action, state)
    case REMOVE_PLAYER:
      return removePlayer(action, state)
    case UPDATE_PLAYER:
      return updatePlayer(action, state)
    case UPDATE_MATCHES:
      return updateMatches(action, state)
    default:
      return state
  }
}
