import React from 'react'
import { Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const style = {
  paddingTop: 25,
}

const EventRound = ({ matches, round, admin, onScoreChange, onDropPlayer }) => {
  if (!matches) return null

  return (
    <div style={style}>
      <h3>Round {round}</h3>

      <table className='sm'>
        <thead>
          <tr>
            <th className='fit' />
            <th className='align-right' width='25%'>
              Player
            </th>
            <th className='align-middle fit' colSpan='2'>
              Score
            </th>
            <th className='align-left' width='25%'>
              Player
            </th>
            <th className='fit' />
          </tr>
        </thead>
        <tbody>
          {matches
            .filter(m => m.round === round)
            // TODO ensure matches are always in the same order, it's annoying when you refresh and they move around
            // .sort((m1, m2) => m1.id - m2.id)
            .map(m => {
              const matchIncludesByePlayer = m.player1.isBye || m.player2.isBye
              return (
                <tr key={m.player1.id + m.player2.id}>
                  <td className='fit'>
                    {admin && !matchIncludesByePlayer ? (
                      <button
                        onClick={event => onDropPlayer(event, m.player1.id, m.round)}
                        className='danger sm'
                      >
                        <FontAwesomeIcon icon='trash-alt' />
                      </button>
                    ) : null}
                  </td>
                  <td className='align-right'>{m.player1.name}</td>
                  <td className='align-middle fit'>
                    {admin && !matchIncludesByePlayer ? (
                      <Form.Control
                        as='select'
                        size='sm'
                        value={m.player1_wins}
                        onChange={event => onScoreChange(event, m.id, m.player1.id)}
                      >
                        <option>0</option>
                        <option>1</option>
                        <option>2</option>
                      </Form.Control>
                    ) : m.player2.isBye ? (
                      2
                    ) : (
                      m.player1_wins
                    )}
                  </td>
                  <td className='align-middle fit'>
                    {admin && !matchIncludesByePlayer ? (
                      <Form.Control
                        as='select'
                        size='sm'
                        value={m.player2_wins}
                        onChange={event => onScoreChange(event, m.id, m.player2.id)}
                      >
                        <option>0</option>
                        <option>1</option>
                        <option>2</option>
                      </Form.Control>
                    ) : m.player1.isBye ? (
                      2
                    ) : (
                      m.player2_wins
                    )}
                  </td>
                  <td className='align-left'>{m.player2.name}</td>
                  <td className='fit'>
                    {admin && !matchIncludesByePlayer ? (
                      <button
                        onClick={event => onDropPlayer(event, m.player2.id, m.round)}
                        className='danger sm'
                      >
                        <FontAwesomeIcon icon='trash-alt' />
                      </button>
                    ) : null}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}

export default EventRound
