import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Context from '../Context/Context'
import Dialog from '../Components/Dialog'
import AddPlayerDialog from '../Components/AddPlayerDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Nav from '../Layouts/Nav'

const AdminLeague = props => {
  const context = useContext(Context)

  const leagueId = props.match.params.leagueId

  const [eventName, setEventName] = useState('')
  const [deletePlayerDialogShow, setDeletePlayerDialogShow] = useState(false)
  const [deletePlayerId, setDeletePlayerId] = useState('')
  const [deleteEventDialogShow, setDeleteEventDialogShow] = useState(false)
  const [deleteEventId, setDeleteEventId] = useState('')
  const [addPlayerModalShow, setAddPlayerModalShow] = useState(false)

  const league = context.leagues.find(l => l.id === leagueId)
  useEffect(() => {
    let players = context.players
    let league = context.leagues.find(l => l.id === leagueId)

    if (players.length === 0) {
      //TODO some loading icon while this fetches
      context.getPlayers()
    }

    //league isn't loaded or we don't have the full object, to get it
    if (!league || !league.fullObject) {
      //TODO some loading icon while this fetches
      context.getLeague(leagueId)
    }
  }, []) //will only ever run once, when mounted

  const handleSubmitEvent = e => {
    e.preventDefault()
    const leagueId = props.match.params.leagueId

    context.addEvent(leagueId, eventName)
    setEventName('')
  }

  const handleDeletePlayer = playerId => {
    setDeletePlayerDialogShow(true)
    setDeletePlayerId(playerId)
  }

  const handlePurchasePack = playerId => {
    const leagueId = props.match.params.leagueId
    context.purchasePack(leagueId, playerId)
  }

  const handleDeleteEvent = eventId => {
    setDeleteEventDialogShow(true)
    setDeleteEventId(eventId)
  }

  const confirmDeleteEvent = () => {
    const leagueId = props.match.params.leagueId
    const eventId = deleteEventId

    closeConfirmDialog()
    context.deleteEvent(leagueId, eventId)
  }

  const confirmDeletePlayer = () => {
    const leagueId = props.match.params.leagueId
    const playerId = deletePlayerId

    closeConfirmDialog()
    context.removePlayer(leagueId, playerId)
  }

  const closeConfirmDialog = () => {
    setDeleteEventDialogShow(false)
    setDeletePlayerDialogShow(false)
  }

  const handleAddPlayers = e => {
    e.preventDefault()
    setAddPlayerModalShow(true)
  }

  const handleAddPlayer = e => {
    e.preventDefault()
    const leagueId = props.match.params.leagueId
    const playerId = e.target.id
    context.addPlayer(leagueId, playerId)
  }

  if (!league) return null

  const playerModalClose = () => setAddPlayerModalShow(false)

  return (
    <React.Fragment>
      <Nav />
      <section className='section-admin'>
        {deletePlayerDialogShow && (
          <Dialog
            show={deletePlayerDialogShow}
            title={'Delete Player?'}
            content={'Are you sure you wish to remove this player from the league?'}
            onHide={() => closeConfirmDialog()}
            footer={
              <React.Fragment>
                <button className='primary' onClick={() => closeConfirmDialog()}>
                  Cancel
                </button>
                <button className='danger' onClick={() => confirmDeletePlayer()}>
                  Yes, delete
                </button>
              </React.Fragment>
            }
          />
        )}

        {deleteEventDialogShow && (
          <Dialog
            show={deleteEventDialogShow}
            title={'Delete Event?'}
            content={'Are you sure you wish the delete this event and all its contents?'}
            onHide={() => closeConfirmDialog()}
            footer={
              <React.Fragment>
                <button className='primary' onClick={() => closeConfirmDialog()}>
                  Cancel
                </button>
                <button className='danger' onClick={() => confirmDeleteEvent()}>
                  Yes, delete
                </button>
              </React.Fragment>
            }
          />
        )}

        {addPlayerModalShow && (
          <AddPlayerDialog
            show={addPlayerModalShow}
            onClose={playerModalClose}
            onAddPlayer={e => handleAddPlayer(e)}
            players={context.players}
            league={league}
          />
        )}

        <div className='row'>
          <h1>{league.name}</h1>
        </div>
        <div className='row breadcrumb'>
          <Link to='/admin'>Admin</Link>
          &nbsp;&nbsp;/&nbsp;&nbsp;
          {league.name}
        </div>
        <div className='row'>
          <div className='col span-1-of-2'>
            <h2>Players ({league.players ? league.players.length : 0})</h2>
            <table className='sm'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th className='align-middle'>Packs Purchased</th>
                  <th colSpan={2} />
                </tr>
              </thead>
              <tbody>
                {league.players
                  .sort((p1, p2) => (p1.name.toUpperCase() > p2.name.toUpperCase() ? 1 : -1))
                  .map(player => (
                    <tr key={player.id}>
                      <td className='grow'>
                        <Link to={'/players/' + player.id}>{player.name}</Link>
                      </td>

                      <td className='align-middle'>
                        {player.nbPacksPurchased ? player.nbPacksPurchased : 0}
                      </td>

                      <td>
                        {player.eligiblePacks > 0 ? (
                          <button
                            onClick={() => handlePurchasePack(player.id)}
                            className='primary sm'
                            id={player.id}
                          >
                            <FontAwesomeIcon icon='dollar-sign' />
                          </button>
                        ) : (
                          player.nextLossEligible === true && <FontAwesomeIcon icon='asterisk' />
                        )}
                      </td>

                      <td>
                        <button
                          onClick={() => handleDeletePlayer(player.id)}
                          className='danger sm'
                          id={player.id}
                        >
                          <FontAwesomeIcon icon='trash-alt' />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <form>
              <button className='primary' type='submit' onClick={event => handleAddPlayers(event)}>
                Add Player(s)
              </button>
            </form>
          </div>

          <div className='col span-1-of-2'>
            <h2>Events ({league.events ? league.events.length : 0})</h2>
            <table className='sm'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {league.events.map(event => (
                  <tr key={event.id}>
                    <td className='grow'>
                      <Link to={'/admin/league/' + league.id + '/event/' + event.id}>
                        {event.name}
                      </Link>
                    </td>
                    <td>
                      <button
                        onClick={() => handleDeleteEvent(event.id)}
                        className='danger sm'
                        id={event.id}
                      >
                        <FontAwesomeIcon icon='trash-alt' />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className='row'>
              <form className='add-event-form' onSubmit={e => handleSubmitEvent(e)}>
                <input
                  placeholder='Event name'
                  id='eventName'
                  value={eventName}
                  onChange={e => setEventName(e.target.value)}
                  required
                  autoComplete='off'
                />
                <button className='primary' type='submit'>
                  Add Event
                </button>
              </form>
            </div>
          </div>
        </div>
        <div id='bg'>
          <img src='/img/jace.jpg' alt='' />
        </div>
      </section>
    </React.Fragment>
  )
}
export default AdminLeague
