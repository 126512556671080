import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Context from '../Context/Context'

const Nav = props => {
  const context = useContext(Context)

  return (
    <nav>
      <div className='row'>
        <ul className='main-nav'>
          <li>
            <a href='/#about'>About</a>
          </li>
          <li>
            <a href='/#standings'>Standings</a>
          </li>
          <li>
            <a href='/#players'>Players</a>
          </li>
          <li>
            <a href='/#rules'>Rules</a>
          </li>
          <li>
            <Link to='/admin'>Admin</Link>
          </li>
          <li>
            {context.authenticated && (
              <Link to='' onClick={() => context.logout()}>
                Logout
              </Link>
            )}
          </li>
        </ul>
        <a className='mobile-nav-icon'>
          <i className='icon ion-md-menu'></i>
        </a>
      </div>
    </nav>
  )
}

export default Nav
