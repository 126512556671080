import React from 'react'

export default React.createContext({
  authenticated: null,
  leagues: [],
  players: [],

  //for better auto-completion
  login: () => {},
  logout: () => {},
  getPlayers: () => {},
  getPlayer: playerId => {},
  getLeague: leagueId => {},
  getLeagues: () => {},
  addLeague: leagueName => {},
  createPlayer: playerName => {},
  deleteLeague: leagueId => {},
  addEvent: (leagueId, eventName) => {},
  deleteEvent: (leagueId, eventId) => {},
  addPlayer: (leagueId, playerId) => {},
  removePlayer: (leagueId, playerId) => {},
  purchasePack: (leagueId, playerId) => {},
  getStandings: leagueId => {},
  generatePairings: (eventId, leagueId, playerIds, rounds) => {},
  dropPlayer: (eventId, leagueId, playerId, round) => {},
  saveScores: (leagueId, eventId, matches) => {},

  //notifications
  notify: (message, type) => {},
  clearNotification: () => {},
})
